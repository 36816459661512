import "regenerator-runtime/runtime";
import Vue from 'vue'

import VueRouter from 'vue-router'

import core_routes from './routes'
import mixins from './mixins'
import Validators from './plugins/validators'
import Axios from 'axios'

import { ValidateResults } from './components/ValidateResults';

Vue.use(VueRouter)

Vue.config.productionTip = false;
Vue.prototype.$http = Axios;

const COOKIES_PARAM_NAME = 'privacy-policy';
const COOKIES_DAYS = 30;

var defaultOptions = {
    storage: 'session',
    showBackButtonOnValidation: true
};

var defaultConfig = {
    mainUrl: '/',
    product_id: null,
    offer_product_id: null,
    loadOffer: false,
    loadTestAB: false,
    test_ab_tried: false,
    defaultOffer: {},
    offer: {},
    // conversions
    view_form_event_id: null,
    domain_id: null,
    form_id: null,
    thank_you_id: null,
    test_ab: null,
    //
    state: {},
    clearStorage: true,
    trackingUrl: 'https://tracking.andromedacrm.com/',
    submitUrl: '/api/submit.php',
    thankYouUrl: '/thank-you',
    phoneValidationUrl: '/api/phoneValidation.php',
    steps: [],
    submitTried: false,
    thankYouParams: {},
    thankYouDisabled: false,
    beforeSubmit: function(){},
    afterSubmit: function(){},
    onLoad: function(){},
    addToFormBeforeSubmit: function(form){},
    options: defaultOptions,
    atatus: true,
    recaptcha: null
};

var defaultStepConfig = {
    showContinueButton: false,
    showBackButton: true,
    showSubmitButton: false,
    hasOwnContinueButton: false,
    validateBeforeNextStep: false,
    scrollTo: {
        mobile: 'app',
        desktop: 'app',
        afterValidation: null
    }
};

var App = {
    main: async function (app_config) {
        var config = Object.assign(defaultConfig, app_config);
        config.options = Object.assign(defaultOptions, app_config.options);

        var states  = {};
        var storage = config.options.storage != null ? JSON.parse(sessionStorage.getItem('state_' + config.mainUrl)) : null;

        config.steps.map(async (route) => {
            var state = {};
            if( route.component && typeof route.component === 'function' && route.componentName ) { 
                await route.component().then(async (module) => {
                    route.component = module[route.componentName];
                })
            }
            if ( storage != null ) {
                state = storage[route.name];
            } else if ( route.component.state !== undefined ) {
                state = route.component.state;
            }
            states[route.name] = state;
            route.component.data = () => { return states[route.name] };

            if( route.component && typeof route.component === 'function' && route.componentName ) { 
                await route.component().then(async (module) => {
                    const obj = module[route.componentName];
                    Vue.component(route.name, obj);
                })
            } else {
                await Vue.component(route.name, route.component);
            }
        })

        config.components.map(async (component) => {
            if( component.component && typeof component.component === 'function' && component.componentName ) { 
                await component.component().then((module) => {
                    Vue.component(component.name, module[component.componentName]);
                })
            } else {
                await Vue.component(component.name, component.component);
            }
        })

        Vue.component('validate-results', ValidateResults);

        let root_state = {
            currentStepConfig: defaultStepConfig,
            history: [],
            getParams: {},
            marketing: {},
            clickid: null,
            states: states, //     Object.assign(states, storage);
            isMobile: false,
            mainLoading: false,
            validationProcess: false,
            transition: 'slide-left',
            isCookiebarVisible: true,
            showForm: false,
            nextStepProgress: false,
            ...config
        };

        config.steps = await Promise.all(config.steps.map(async (step) => {
            if( step.component && typeof step.component === 'function' && step.componentName ) { 
                let component = step.component;
                await step.component().then(async (module) => {
                    component = await module[step.componentName];
                })

                const result = {
                    ...step,
                    component: component,
                    data: () => { return component.state }
                }
                return result
            } else { 
                const result = {
                    ...step,
                    data: () => { return step.component.state }
                }
                return result
            }
        }))

        var routes = config.steps.concat(core_routes);//.concat(config.steps);

        var router = new VueRouter({
            mode: 'history',
            base: config.mainUrl,
            routes
        })

        window.root_state = root_state;

        var App = {
            router,

            data() {
                return window.root_state
            },

            watch: {
                $route(to, from) {
                    this.currentStepConfig = Object.assign({}, defaultStepConfig, to.meta)

                    // we don't want to scroll for the first load
                    if ( from.name !== undefined) {
                        this.scrollTo();
                    }
                },
                'states': {
                    handler: function(currentData, oldData) {
                        if ( this.storage != null ) {
                            this.storage.setItem('state_' + config.mainUrl, JSON.stringify(currentData));
                        }
                    },
                    deep: true
                },
                clickid(to, from) {
                    if ( from == null ) {
                        this.marketing.clickid = to;
                        this.fireEvent(this.view_form_event_id);
                        this.fireEvent(this.$route.meta.formEventId);
                    }
                }
            },
            methods: {
                /** NAVIGATION */
                nextStep: async function(step_name) {
                    await this.delay(0);
                    if ( this.nextStepProgress ) {
                        return;
                    }
                    this.nextStepProgress = true;
                    this.transition = 'slide-left';

                    this.states[this.$route.name].hasValue = true;

                    if ( this.$route.meta.loadingBeforeLeave ) {
                        this.mainLoading = true;
                        await this.delay(this.$route.meta.loadingBeforeLeave.time * 1000);
                        this.mainLoading = false;
                    }

                    if ( this.$route.meta.validateBeforeNextStep ) {
                        if ( !this.currentStep.$options.isValid(Vue.$validators(), this.states[this.$route.name]) ) {
                            this.scrollAfterValidation();
                            return;
                        }
                    } else if ( this.submitTried ) {
                        if (  this.$route.meta.validateBeforeNextStep  && typeof this.currentStep.$options.isValid === 'function' ) {
                            if (this.currentStep.$options.isValid(Vue.$validators(), this.states[this.$route.name])) {
                                for (var [name, field] of Object.entries(this.states[this.$route.name].form)) {
                                    this.states[this.$route.name].form[name].error = null;
                                }
                            }
                        }
                    }

                    var nextStep = null;
                    if ( step_name != null ) {
                        nextStep = step_name;

                    } else if ( typeof this.currentStep.getNextStep === 'function' ) {
                        nextStep = this.currentStep.getNextStep();
                    } else {
                        var nextIndex = this.$route.meta.index + 1;
                        nextStep = this.steps[nextIndex].name;
                    }

                    var nextStepObject = this.$router.resolve({name: nextStep});

                    if ( nextStepObject && nextStepObject.route.meta.formEventId ) {
                        this.fireEvent(nextStepObject.route.meta.formEventId);
                    }

                    this.$set(this.states[nextStep], 'visited', true);

                    router.push({name: nextStep});

                    // scroll to top
                },
                fireEvent: async function(formEventId) {
                    if ( this.marketing.clickid && formEventId ) {
                        try {
                            const params = new URLSearchParams();
                            params.append('clickid', this.marketing.clickid);
                            params.append('event_id', formEventId);
                            params.append('form_id', this.form_id);
                            params.append('test_id', this.test_ab ? this.test_ab.test_id : null);
                            params.append('test_ab_id', this.test_ab ? this.test_ab.test_ab_id : null);

                            if (this.isMobile) {
                                params.append('mobile', 1);
                            } else {
                                params.append('mobile', 0);
                            }

                            await this.$http.post(this.trackingUrl, params);
                        } catch (err) {
                            console.log(err);
                        }
                    }
                },
                force: function() {
                    this.$forceUpdate();
                },
                prevStep: function() {
                    if ( typeof this.currentStep.$options.beforeBack === 'function' ) {
                        this.currentStep.$options.beforeBack();
                    }

                    this.transition = 'slide-right';
                    router.go(-1);
                },
                scrollTo: function(containerId = null) {
                    if ( containerId == null) {
                        containerId = this.isMobile ? this.currentStepConfig.scrollTo.mobile : this.currentStepConfig.scrollTo.desktop
                    }
                    let container = document.getElementById(containerId);

                    if (container != null) {
                        this.$nextTick(()=>{
                            container.scrollIntoView();
                        })
                    }
                },
                scrollToTop: function() {
                    window.scrollTo(0,0);
                },
                scrollAfterValidation: function() {
                    if ( this.$route.meta.scrollTo.afterValidation != null ) {
                        this.scrollTo(this.$route.meta.scrollTo.afterValidation);
                    }
                },
                testABView: function (test_id = null, test_ab_id = null) {
                    this.testABAction('view', test_id, test_ab_id);
                },
                testABClick: function (test_id = null, test_ab_id = null) {
                    this.testABAction('click', test_id, test_ab_id)
                },
                testABAction: function (action, test_id = null, test_ab_id = null) {
                    var storageKey = 'test_ab_id_' + action + '_' + config.form_id;
                    var wasViewed = this.storage.getItem(storageKey);

                    var params = '?andromeda_click_id=' + this.androClickId;

                    if ( wasViewed ) {
                        return;
                    }

                    if ( this.test_ab ) {
                        this.$http.post('https://andromedacrm.com/api/tests/ab/' + config.form_id + '/' + ( test_id ?? this.test_ab.test_id ) + '/' + ( test_ab_id ?? this.test_ab.test_ab_id ) + '/' + action + params);
                    } else if ( test_id != null && test_ab_id != null ) {
                        this.$http.post('https://andromedacrm.com/api/tests/ab/' + config.form_id + '/' + test_id + '/' + test_ab_id  + '/' + action + params);
                    }


                    this.storage.setItem(storageKey, true);
                },
                /** SUBMIT */
                validate: function() {
                    return new Promise(async (resolve, reject) => {
                        var isValid = true;

                        for (var [step, route] of Object.entries(this.steps)) {
                            if (typeof route.component.isValid == 'function') {
                                var stepStatus = await route.component.isValid(Vue.$validators(), this.states[route.name]);
                                this.steps[step].validationStatus = stepStatus;

                                isValid = isValid && this.steps[step].validationStatus;
                            } else {
                                this.steps[step].validationStatus = true;
                            }
                        }

                        if (isValid)
                            return resolve(isValid);

                        return reject(isValid);
                    });
                },
                submit: function() {
                    config.beforeSubmit();
                    var form = this.getForm();

                    if ( config.mapForm !== undefined ) {
                        form = this.mapFormFields(form, config.mapForm);
                    }

                    config.addToFormBeforeSubmit(form);

                    form.marketing = { ...this.getParams, ...this.marketing };
                    form.marketing.thank_you_url = 'https://' + window.location.hostname + this.thankYouUrl;
                    form.marketing.cookies = document.cookie;
                    form.marketing.offer_product_id = this.offer_product_id;
                    form.marketing.andromeda_click_id = this.androClickId;

                    if ( config.form_id ) {
                        form.marketing.domain_form_id = config.form_id;
                    }

                    this.$http.post(config.submitUrl, form)
                        .then((response) => {
                            this.afterSendLead(response);
                        })
                        .catch(function(error) {
                            console.log(error);
                        });

                },
                afterSendLead: async function(response) {
                    if (config.gtagConverionSendTo) {
                        if ( typeof gtag === 'function') {
                            if (Array.isArray(config.gtagConverionSendTo)) {
                                config.gtagConverionSendTo.forEach((tag) => {
                                    gtag('event', 'conversion', {'send_to': tag});
                                })
                            } else {
                                gtag('event', 'conversion', {'send_to': config.gtagConverionSendTo});
                            }
                        } else {
                            console.warn('Please include google script to use gtag');
                        }
                    }

                    if (this.marketing.clickid) {
                        this.thankYouParams.clickid = this.marketing.clickid;
                    }

                    if (response.data && response.data.data) {
                        sessionStorage.setItem('lead_id', response.data.data);
                    }

                    if (this.getParams.gclid) {
                        this.thankYouParams.gclid = this.getParams.gclid;
                    }

                    if (this.getParams.fbclid) {
                        this.thankYouParams.fbclid = this.getParams.fbclid;
                    }

                    this.scrollToTop();

                    if ( config.clearStorage ) {
                        this.storage.setItem('state_' + config.mainUrl, null);
                    }

                    await this.fireEvent(this.thank_you_id);

                    config.afterSubmit(response);
                    var encodedParams = this.encodeQueryData(this.thankYouParams);

                    if ( encodedParams != '' ) {
                        encodedParams = '?' + encodedParams;
                    }

                    if ( config.thankYouDisabled == false ) {
                        window.location.href = this.thankYouUrl + encodedParams;
                    }
                },
                encodeQueryData: function(parameters) {
                    const ret = [];
                    for (let d in parameters)
                        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(parameters[d]));
                    return ret.join('&');
                },
                mapFormFields: function(form, fromTo) {
                    for (const [from, to] of Object.entries(fromTo)) {
                        form[to] = form[from];

                        if ( from != to ) {
                            delete form[from];
                        }
                    }

                    return form;
                },
                getForm: function() {
                    var form  = {};
                    form.product_id = this.product_id;

                    for (var [step, object] of Object.entries(this.states)) {

                        if ( typeof object.form === 'object' ) {

                            for (var [name, field] of Object.entries(object.form)) {
                                form[name] = field.value
                            }
                        }
                    }

                    return form;
                },
                delay: function(ms) {
                    return new Promise(res => setTimeout(res, ms));
                },
                validateAndSubmit: async function() {
                    this.mainLoading = true;
                    this.submitTried = true;

                    if ( this.$route.meta.validateBeforeNextStep ) {
                        var result = await this.currentStep.$options.isValid(Vue.$validators(), this.states[this.$route.name]);

                        if ( !result) {
                            this.mainLoading = false;
                            this.submitTried = false;
                            this.scrollAfterValidation();
                            return;
                        }
                    }


                    await this.delay(100);

                    await this.validate().then((response) => {
                            return this.submit();
                        }).catch((err)=> {
                            if (this.$route.name == 'validate') {
                                this.$forceUpdate();
                            } else {
                                //router.push('validate');
                                this.nextStep('validate');
                            }
                            this.mainLoading = false;
                        }).finally(()=> {
                            this.$forceUpdate();
                        });
                },
                setUpStorage() {
                    switch (config.options.storage) {
                        case 'local':
                            this.storage = localStorage;
                        break;

                        case 'session':
                            this.storage = sessionStorage;
                        break;
                    }
                },
                validators() {
                    return Vue.$validators();
                },
                // for mounted
                getUrlVars: function (url = null) {
                    var vars = {};

                    var check = url ? url : window.location.href;
                    check.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(substring, key, value) {
                        vars[key] = decodeURIComponent(value);
                    });
                    return vars;
                },
                beforeLeave(element) {
                    this.prevHeight = getComputedStyle(element).height;
                },
                enter(element) {
                    const { height } = getComputedStyle(element);

                    element.style.height = this.prevHeight;

                    setTimeout(() => {
                        element.style.height = height;
                    });
                },
                afterEnter(element) {
                    element.style.height = 'auto';
                    this.nextStepProgress = false;
                },
                getCookiesStatus() {
                    const v = document.cookie.match('(^|;) ?' + COOKIES_PARAM_NAME + '=([^;]*)(;|$)')
                    return v ? this.isCookiebarVisible = false : this.isCookiebarVisible = true;
                },
                closeCookies() {
                    var d = new Date;
                    d.setTime(d.getTime() + 24*60*60*1000*COOKIES_DAYS);
                    document.cookie = COOKIES_PARAM_NAME + "=" + true + ";path=/;expires=" + d.toGMTString();
                    this.isCookiebarVisible = false;
                },
                clearErrorAndKeepVisible(field) {
                    field.keepVisible = true;
                    field.error = null;
                },
                onLoad() {
                    config.onLoad();
                },
                async loadTestABInit() {
                    var url = window.location.href.split('?');
                    var params = '?';

                    if ( url.length == 2) {
                        params += url[1] + '&';
                    }

                    params += 'ism=' + (this.isMobile ? 'true' : 'false' );
                    params += '&andromeda_click_id=' + this.androClickId;

                    if ( config.loadTestAB ) {
                        var keyTestAB = 'test_ab_id_' + config.form_id;
                        var storageTestAB = JSON.parse(this.storage.getItem(keyTestAB));

                        if ( storageTestAB == null ) {
                            await this.$http.get('https://andromedacrm.com/api/tests/ab/' + config.form_id + params)
                                .then((response) => {
                                    this.test_ab = response.data;
                                    this.storage.setItem(keyTestAB, JSON.stringify(this.test_ab));
                                })
                                .catch((error) => {
                                    this.$http.get('https://andromedacrm.com/errorTest?' + JSON.stringify(error) );
                                })
                                .finally(() => {
                                    this.test_ab_tried = true;
                                });
                        } else {
                            this.test_ab = storageTestAB;
                            this.test_ab_tried = true;
                        }
                    }
                },
                forceOffer: async function(requestObject) {
                    let key = 'offer_' + this.form_id;
                    config.loadOffer = true;

                    let additionalParams = '';
                    additionalParams += 'ism=' + (this.isMobile ? 'true' : 'false' );
                    additionalParams += '&andromeda_click_id=' + this.androClickId;
                    if( requestObject && requestObject.hasOwnProperty('uprn') && requestObject.uprn ) additionalParams += '&uprn=' + requestObject.uprn;

                    let marketingParamsString = '';
                    if( this.getParams && Object.keys(this.getParams).length > 0 ) { 
                        Object.entries(this.getParams).forEach((obj) => {
                            marketingParamsString += '&' + obj[0] + '=' + obj[1];
                        })
                    }
                    const urlParams = '?' + additionalParams + marketingParamsString;
                    const requestParams = this.getUrlVars(urlParams)

                    var bodyFormData = new FormData();
                    if( Object.keys(requestObject).length > 0 ) {
                        let mappedObject = Object.assign({}, requestObject);
                        if ( config.mapForm !== undefined ) {
                            mappedObject = this.mapFormFields(mappedObject, config.mapForm);
                        }
                        for (const [key, value] of Object.entries(mappedObject) ) {
                            bodyFormData.append('form_fields['+key+']', value);
                        }
                    }

                    let requestUrl = 'https://andromedacrm.com/api/offers/' + this.form_id;
                    let returnsNull = false;
                    await this.$http.post(requestUrl + urlParams, 
                        bodyFormData,
                        {
                            headers: { "Content-Type": "multipart/form-data" },
                        },
                    )
                        .then((response) => {
                            if (!response || !response.data || !response.data.data || (response.data.data.potential && !response.data.data.data)) {
                                returnsNull = true;
                            }
                            this.offer = response.data.data;
                            if( (response.data.data.potential && !response.data.data.data) ) { 
                                response.data.data.data = { 
                                    thankyou: this.thankYouUrl
                                }
                            }
                        })
                        .catch((error) => {
                            this.offer = this.defaultOffer;
                            returnsNull = true;
                        })
                        .finally(() => {
                            sessionStorage.setItem('is-offer-null', returnsNull)
                            if( !this.offer ) return null;

                            this.product_id = this.offer.product_id ? this.offer.product_id : this.product_id;
                            this.offer_product_id = this.offer.offer_product_id ? this.offer.offer_product_id : this.offer_product_id;
                            this.thankYouUrl = this.offer.data && this.offer.data.thankyou ? this.offer.data.thankyou : this.thankYouUrl;
                            this.thankYouUrl = this.offer.thankyou ? this.offer.thankyou : this.thankYouUrl;
                            this.offer = {
                                ...this.offer,
                                returnsNull,
                            }

                            // Store the offer for eventual later use
                            this.storage.setItem(key, JSON.stringify(this.offer));
                            this.storage.setItem(key + "_" + this.product_id, JSON.stringify({
                                product_id: this.product_id,
                                offer_product_id: this.offer_product_id,
                                thankYouUrl: this.thankYouUrl,
                                data: JSON.stringify(this.offer),
                            }));
                        });

                    // Update main one so on refresh, it is always the latest one the customer selected
                    this.storage.setItem("offer_" + this.form_id, JSON.stringify({
                        product_id: this.product_id,
                        offer_product_id: this.offer_product_id,
                        thankYouUrl: this.thankYouUrl,
                        data: this.offer.data ? this.offer.data : null,
                    }));
                },
                afterClickId: function() {
                    var url = window.location.href.split('?');
                    var params = '?';

                    if ( url.length == 2) {
                        params += url[1] + '&';
                    }

                    params += 'ism=' + (this.isMobile ? 'true' : 'false' );
                    params += '&andromeda_click_id=' + this.androClickId;
                    if( this.getFormValues.hasOwnProperty('uprn') && this.getFormValues.uprn ) params += '&uprn=' + this.getFormValues.uprn;

                    if ( config.loadOffer ) {
                        var key = 'offer_' + config.form_id;
                        var storageOffer = JSON.parse(this.storage.getItem(key));

                        if (storageOffer == null || this.getParams.offer_product_id > 0 ) {

                            this.$http.get('https://andromedacrm.com/api/offers/' + config.form_id + params)
                                .then((response) => {
                                    this.offer = response.data.data;
                                })
                                .catch((error) => {
                                    this.offer = this.defaultOffer;
                                    this.$http.get('https://andromedacrm.com/errorOffer?' + JSON.stringify(error) );
                                })
                                .finally(() => {
                                    this.product_id = this.offer.product_id;
                                    this.offer_product_id = this.offer.offer_product_id;
                                    this.thankYouUrl = this.offer.data.thankyou;
                                    this.storage.setItem(key, JSON.stringify(this.offer));
                                });
                        } else {
                            this.offer = storageOffer;
                            this.offer_product_id = this.offer.offer_product_id;
                            this.product_id = storageOffer.product_id;
                            this.thankYouUrl = storageOffer.data.thankyou;
                        }
                    }

                    this.loadTestABInit();
                }
            },

            computed: {
                currentStep() {
                    return this.$route.matched[0].instances.default
                },
                currentIndex() {
                    return this.currentStepConfig.index;
                },
                currentStepName() {
                    if (this.currentStepConfig) {
                        return null;
                    }

                    this.currentStepConfig.name;
                },
                showBackButton()  {
                    return (this.currentStepConfig.showBackButton && this.currentStepConfig.index > 0); // || validation step
                },
                showContinueButton()  {
                    return this.currentStepConfig.showContinueButton;
                },
                hasOwnContinueButton() {
                    return this.currentStepConfig.hasOwnContinueButton;
                },
                showSubmitButton()  {
                    return this.currentStepConfig.showSubmitButton;
                },
                stepWasVisited() {
                    return this.states && this.states[this.$route.name] && this.states[this.$route.name].visited;
                },
                stepHasValue() {
                    return this.states && this.states[this.$route.name] && this.states[this.$route.name].hasValue === true;
                },
                getFormValues() {
                    return this.getForm();
                },
            },
            async mounted() {
                this.showForm = false;

                var sourceUrl = this.storage.getItem('sourceUrl');

                this.isMobile = document.getElementsByTagName('html')[0].clientWidth < 860;

                this.showForm = true;
                this.getParams = this.getUrlVars();

                if ( window['dtpCallback'] !== undefined ) {
                    window['dtpCallback'].registerConversion( async (clickId) => {
                        this.clickid = clickId;
                    });
                } else if ( this.getParams.clickid ) {
                    this.clickid = this.getParams.clickid;
                }

                if ( sourceUrl ) {
                    this.marketing.url = sourceUrl;
                    this.marketing.referrer = this.storage.getItem('referrer');
                    this.getParams = this.getUrlVars(sourceUrl);
                } else {
                    this.storage.setItem('sourceUrl', window.location.href);
                    this.storage.setItem('referrer', document.referrer);
                    this.marketing.url = window.location.href;
                    this.marketing.referrer = document.referrer;
                }

                this.currentStepConfig = Object.assign({}, defaultStepConfig, this.$route.matched[0].meta);

                // Cookies
                this.getCookiesStatus()

                this.onLoad();
            },

            created() {
                this.setUpStorage();

                var sourceUrl = this.storage.getItem('sourceUrl');

                if ( (this.states[this.$route.name] === undefined || this.states[this.$route.name].visited === undefined) && this.$route.path != '/' ) {
                    if ( sourceUrl ) {
                        window.location.href = sourceUrl;
                    } else {
                        window.location.href = config.mainUrl;
                    }

                    return;
                }

                var getParams = this.getUrlVars();

                var sentView = 'sentView_' + config.form_id;
                if ( config.form_id && !this.storage.getItem(sentView) ) {
                    var ism = document.getElementsByTagName('html')[0].clientWidth < 860;
                    const script = document.createElement('script');
                    script.src = 'https://andromedacrm.com/t/' + config.form_id + '.js?ism=' + (ism ? 1 : 0);
                    //script.src = 'http://127.0.0.1:8000/t/' + config.form_id + '.js?ism=' + (ism ? 1 : 0);
                    if ( window.location.search ) {
                        script.src += '&' + window.location.search.substring(1);
                    }
                    script.async = false;
                    script.onload = () => {
                        this.androClickId = window.androClickId;
                        this.storage.setItem(sentView, window.androClickId);
                        this.afterClickId();
                    }
                    script.onerror = () => {
                        var xhr = new XMLHttpRequest();
                        xhr.open("GET", 'https://andromedacrm.com/errorJSTAG', true);
                        xhr.send();
                        this.afterClickId();
                    };
                    document.scripts[0].parentNode.insertBefore(script, document.scripts[0]);

                    var xhr = new XMLHttpRequest();
                    xhr.open("POST", 'https://andromedacrm.com/api/domainForm/' + config.form_id + '/view', true);
                    xhr.onreadystatechange = function() {
                        if (this.readyState == 4 && this.status != 200 ) {
                            app.$http.get('https://andromedacrm.com/errorDomainFormView?status=' +this.status +'&response='+ xhr.responseText );
                        }
                    };
                    xhr.onerror = function(error) {
                        app.$http.get('https://andromedacrm.com/errorDomainFormView?error' + JSON.stringify(error) );
                    };
                    xhr.send();

                } else {
                    this.androClickId = this.storage.getItem(sentView);
                    this.afterClickId();
                }
            }
        };

        Vue.use(Validators);
        Vue.mixin(mixins);

        return new Vue(App).$mount('#app');
    }
};

window.FormApp = App;

window.addEventListener( "pageshow", function ( event ) {
    var historyTraversal = event.persisted || ( typeof window.performance != "undefined" && window.performance.navigation.type === 2 );

    if ( historyTraversal ) {
        window.location.reload();
    }
});


